import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AsyncStateProvider } from 'react-async-states';
import AuthProvider from '../core/keycloak';

type AppProvidersProps = {
  children: React.ReactNode;
};

export default function AppProviders({ children }: AppProvidersProps) {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AsyncStateProvider>{children}</AsyncStateProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
