import * as React from 'react';
import { IconProps } from '../../types';

export default function LeftArrowIcon({ className }: IconProps) {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.370401 5.47545L4.7825 1.13144C5.08746 0.831188 5.58057 0.831188 5.88228 1.13144L6.61547 1.85331C6.92042 2.15356 6.92042 2.63906 6.61547 2.93612L3.49131 6.01845L6.61871 9.09758C6.92367 9.39783 6.92367 9.88334 6.61871 10.1804L5.88553 10.9055C5.58057 11.2057 5.08746 11.2057 4.78575 10.9055L0.373646 6.56145C0.0654474 6.2612 0.0654475 5.77569 0.370401 5.47545V5.47545Z"
        fill="#828282"
      />
    </svg>
  );
}
