import * as React from 'react';
import { IconProps } from '../../types';

export default function ViewIcon({ className }: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.8983 7.68879C15.7554 7.49326 12.3496 2.90109 7.99992 2.90109C3.65019 2.90109 0.244313 7.49326 0.101531 7.6886C-0.0338438 7.8741 -0.0338438 8.1257 0.101531 8.3112C0.244313 8.50673 3.65019 13.0989 7.99992 13.0989C12.3496 13.0989 15.7554 8.5067 15.8983 8.31135C16.0339 8.12588 16.0339 7.8741 15.8983 7.68879ZM7.99992 12.044C4.79588 12.044 2.02085 8.99604 1.19938 7.99963C2.01979 7.00235 4.78901 3.95603 7.99992 3.95603C11.2038 3.95603 13.9787 7.00341 14.8005 8.00035C13.9801 8.9976 11.2108 12.044 7.99992 12.044Z"
        fill="currentColor"
      />
      <path
        d="M8.00005 4.83508C6.25499 4.83508 4.83521 6.25487 4.83521 7.99993C4.83521 9.745 6.25499 11.1648 8.00005 11.1648C9.74511 11.1648 11.1649 9.745 11.1649 7.99993C11.1649 6.25487 9.74511 4.83508 8.00005 4.83508ZM8.00005 10.1098C6.83661 10.1098 5.89017 9.16334 5.89017 7.99993C5.89017 6.83652 6.83664 5.89005 8.00005 5.89005C9.16346 5.89005 10.1099 6.83652 10.1099 7.99993C10.1099 9.16334 9.16349 10.1098 8.00005 10.1098Z"
        fill="currentColor"
      />
    </svg>
  );
}
