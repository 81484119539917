import { Button, Modal } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { CloseIcon } from '../../../assets/Icons';
import { formattedMessages } from '../../../utils/formattedMessages';
import './styles.scss';

type ConfirmationModalPropsType = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  bodyMessage?: string;
  confirmationFunction: Function;
};

function ConfirmationModal({
  isVisible,
  setIsVisible,
  bodyMessage = t(formattedMessages.confirmCancel),
  confirmationFunction,
}: ConfirmationModalPropsType) {
  return (
    <Modal
      width={430}
      closeIcon={<CloseIcon />}
      onCancel={() => setIsVisible(false)}
      title={
        <span className="text-xl">{t(formattedMessages.logoutTitle)}</span>
      }
      className="confirmation-modal"
      visible={isVisible}
      footer={false}
    >
      <div className="logout-message">{bodyMessage}</div>
      <div className="w-full flex justify-end gap-2 mt-1 footer">
        <Button
          className="!w-32 !px-6 !py-5 !h-10 !rounded-lg"
          htmlType="button"
          onClick={() => setIsVisible(false)}
        >
          {t(formattedMessages.cancel)}
        </Button>
        <Button
          className="!w-32 !px-6 !py-5 !h-10 !rounded-lg"
          type="primary"
          htmlType="button"
          onClick={() => confirmationFunction()}
        >
          {t(formattedMessages.continue)}
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
