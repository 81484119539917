// eslint-disable-next-line @typescript-eslint/naming-convention
export const __DEV__ = process.env.NODE_ENV !== 'production';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const __TEST__ = process.env.NODE_ENV === 'test';

export const EMPTY_ARRAY = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});
export const EMPTY_STRING = Object.freeze('');

export const fetchStatusTypes = Object.freeze({
  INITIAL: 'initial',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
  ABORTED: 'aborted',
});

export const userRoles = Object.freeze({
  ADMIN: 'admin',
  REVIEWER: 'reviewer',
});

export const KEYCLOAK_REALM_URL = `${process.env.KEYCLOAK_BASE_URL}/auth/realms/xhub`;
export const KEYCLOAK_LOGOUT_URL = `${KEYCLOAK_REALM_URL}/protocol/openid-connect/logout`;
