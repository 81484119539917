import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { __TEST__ } from './utils/constants';

const i18nInstance = i18n;

if (!__TEST__) {
  i18nInstance.use(Backend);
}

i18nInstance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: !__TEST__,
    },
  });

export default i18nInstance;
