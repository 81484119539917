import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GoodiesRouteConfig } from '../pages/listGoodies/route';
import { EventsListRouteConfig } from '../pages/eventsList/routes';
import { QuestionsRouteConfig } from '../pages/listOfQuestions/route';
import { ParticipantsRouteConfig } from '../pages/participants/route';
import { XQuizRoute } from '../types';

type AppRoutingProps = {
  routes: string;
};
function renderRouteElement(config: XQuizRoute<any>) {
  return React.createElement(config.Component, config.ComponentProps);
}

function makeRoutePropsFromConfig(config: XQuizRoute<any>) {
  return {
    path: config.path,
    element: renderRouteElement(config),
  };
}
const GLOBAL_ROUTE_CONFIG = Object.freeze({
  APP_ROUTES_CONFIG: [
    makeRoutePropsFromConfig(ParticipantsRouteConfig),
    makeRoutePropsFromConfig(QuestionsRouteConfig),
    makeRoutePropsFromConfig(GoodiesRouteConfig),
    makeRoutePropsFromConfig(EventsListRouteConfig),
  ],
});

export default function AppRouting({ routes }: AppRoutingProps) {
  return (
    <Routes>
      {GLOBAL_ROUTE_CONFIG[routes]?.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  );
}
