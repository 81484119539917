import * as React from 'react';
import { IconProps } from '../../types';

export default function ScopeIcon({ className }: IconProps) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8967 14.6087L11.1176 10.8296C11.0502 10.7622 10.9624 10.727 10.8686 10.727H10.5669C11.5717 9.64018 12.1869 8.19005 12.1869 6.59345C12.1869 3.22741 9.4595 0.5 6.09345 0.5C2.72741 0.5 0 3.22741 0 6.59345C0 9.9595 2.72741 12.6869 6.09345 12.6869C7.69005 12.6869 9.14018 12.0717 10.227 11.0698V11.3686C10.227 11.4624 10.2651 11.5502 10.3296 11.6176L14.1087 15.3967C14.2464 15.5344 14.469 15.5344 14.6067 15.3967L14.8967 15.1067C15.0344 14.969 15.0344 14.7464 14.8967 14.6087ZM6.09345 11.7494C3.24301 11.7494 0.937454 9.4439 0.937454 6.59345C0.937454 3.74301 3.24301 1.43745 6.09345 1.43745C8.9439 1.43745 11.2494 3.74301 11.2494 6.59345C11.2494 9.4439 8.9439 11.7494 6.09345 11.7494Z"
        fill="currentColor"
      />
    </svg>
  );
}
