import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Layout as AntLayout } from 'antd';
import { APP_BAR_HEIGHT, globalRoutes } from './constants';
import AppRouting from '../Routing';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import privateRoutes from './privateRoutes';

function ProtectedApp() {
  const localtion = useLocation();

  if (localtion?.pathname === privateRoutes.eventsList.path)
    return <AppRouting routes={globalRoutes.APP_ROUTES_CONFIG} />;

  return (
    <AntLayout>
      <AntLayout.Sider
        style={{
          height: `100vh`,
        }}
      >
        <SideMenu />
      </AntLayout.Sider>
      <AntLayout>
        <AntLayout.Header
          style={{ height: APP_BAR_HEIGHT }}
          className="drop-shadow-xl"
        >
          <Header />
        </AntLayout.Header>
        <AntLayout>
          <AntLayout.Content
            style={{
              height: `calc(100vh - ${APP_BAR_HEIGHT}px`,
            }}
            className="bg-white"
          >
            <div className="scroll-y-auto h-full">
              <div className="w-full mx-auto mt-10">
                <AppRouting routes={globalRoutes.APP_ROUTES_CONFIG} />
              </div>
            </div>
          </AntLayout.Content>
        </AntLayout>
      </AntLayout>
    </AntLayout>
  );
}
export default ProtectedApp;
