import * as React from 'react';
import { IconProps } from '../../types';

export default function GiveGoodieIcon({ className }: IconProps) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.5 3H10.9C11 2.6 11 2.2 11 1.8C10.9 1.5 10.8 1.2 10.6 0.9C10.4 0.6 10.2 0.4 9.9 0.3C9.6 0.2 9.3 0 9 0C8.7 0 8.4 4.47035e-08 8.1 0.2C7.4 0.4 6.9 0.9 6.5 1.5C6.1 0.9 5.6 0.4 4.9 0.2C4.6 0.1 4.3 0 4 0C3.7 0 3.4 0.0999999 3.1 0.3C2.8 0.4 2.6 0.6 2.4 0.9C2.2 1.1 2.1 1.5 2 1.8C2 2.2 2 2.6 2.1 3H0.5L0 3.5V12.5L0.5 13H12.5L13 12.5V3.5L12.5 3ZM6 12H1V4H6V12ZM6 3H3V2.8C2.9 2.5 2.9 2.3 2.9 2C3 1.8 3 1.6 3.2 1.5C3.3 1.3 3.5 1.2 3.7 1.1C3.8 1 4 1 4.2 1C4.4 1 4.6 1 4.8 1.1C5.1 1.2 5.4 1.4 5.6 1.7C5.8 2 6 2.3 6 2.7V3ZM7 2.7C7 2.3 7.2 2 7.4 1.7C7.6 1.4 7.9 1.2 8.2 1.1C8.4 1 8.6 1 8.8 1C9 1 9.2 1 9.4 1.1C9.6 1.2 9.7 1.3 9.9 1.5C10 1.6 10 1.8 10.1 2C10.1 2.3 10.1 2.5 10 2.8C10 2.9 10 2.9 9.9 3H7V2.7ZM12 12H7V4H12V12Z"
        fill="currentColor"
      />
    </svg>
  );
}
