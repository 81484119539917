import { Button } from 'antd';
import { t } from 'i18next';
import React from 'react';
import LogoutIcon from '../../assets/Icons/LogoutIcon';
import ConfirmationModal from './LogoutModal';
import { formattedMessages } from '../../utils/formattedMessages';
import { confirmLogout } from '../../core/keycloak/keycloakConfig';

function LogoutButton({ color = 'black' }: { color?: string }) {
  const [isLogoutVisible, setIsLogoutVisible] = React.useState<boolean>(false);

  return (
    <>
      <Button
        onClick={() => setIsLogoutVisible(true)}
        className="!w-fit !border-none !bg-transparent"
        icon={<LogoutIcon color={color} />}
      />
      <ConfirmationModal
        confirmationFunction={confirmLogout}
        bodyMessage={t(formattedMessages.logoutConfirmation)}
        isVisible={isLogoutVisible}
        setIsVisible={setIsLogoutVisible}
      />
    </>
  );
}

export default LogoutButton;
