import * as React from 'react';
import { IconProps } from '../../types';

export default function RightArrowIcon({ className }: IconProps) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.19454 5.47545L2.78244 1.13144C2.47748 0.831188 1.98437 0.831188 1.68266 1.13144L0.949469 1.85331C0.644515 2.15356 0.644515 2.63906 0.949469 2.93612L4.07363 6.01845L0.946225 9.09758C0.641271 9.39783 0.641271 9.88334 0.946225 10.1804L1.67941 10.9055C1.98437 11.2057 2.47748 11.2057 2.77919 10.9055L7.1913 6.56145C7.49949 6.2612 7.49949 5.77569 7.19454 5.47545V5.47545Z"
        fill="#828282"
      />
    </svg>
  );
}
