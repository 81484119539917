import * as React from 'react';
import { IconProps } from '../../types';

export default function GoodiesIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2223 7H2.77777C2.34823 7 2 7.33578 2 7.75V9.25C2 9.66421 2.34823 10 2.77777 10H15.2223C15.6518 10 16 9.66421 16 9.25V7.75C16 7.33578 15.6518 7 15.2223 7Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 7V16"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10V14.6666C14 15.0203 13.8495 15.3594 13.5815 15.6094C13.3137 15.8595 12.9503 16 12.5714 16H5.42857C5.04969 16 4.68632 15.8595 4.41841 15.6094C4.15051 15.3594 4 15.0203 4 14.6666V10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.78571 7C5.31211 7 4.85791 6.78931 4.52302 6.41427C4.18813 6.03923 4 5.53056 4 5.00017C4 4.46979 4.18813 3.96112 4.52302 3.58608C4.85791 3.21104 5.31211 3.00035 5.78571 3.00035C6.47477 2.9869 7.15002 3.36133 7.72338 4.07479C8.29674 4.78824 8.74162 5.80763 9 7C9.25837 5.80763 9.70323 4.78824 10.2766 4.07479C10.85 3.36133 11.5253 2.9869 12.2143 3.00035C12.6879 3.00035 13.1421 3.21104 13.477 3.58608C13.8119 3.96112 14 4.46979 14 5.00017C14 5.53056 13.8119 6.03923 13.477 6.41427C13.1421 6.78931 12.6879 7 12.2143 7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
