import { hasCodeInUrl } from '../hasCodeInUrl';

export function producer({ payload: { manager } }) {
  if (hasCodeInUrl(window.location)) {
    return manager.signinCallback().then(({ access_token, profile }) => {
      localStorage.setItem('token', access_token);
      localStorage.setItem('profile', JSON.stringify(profile));
    });
  }
  return manager.getUser().then((user) => {
    if (!user || user.expired) {
      manager.signinRedirect();
      throw new Error('need to login');
    }
    return user;
  });
}
