export default {
  participants: Object.freeze({
    path: '/participants',
  }),
  questions: Object.freeze({
    path: '/questions',
  }),
  goodies: Object.freeze({
    path: '/goodies',
  }),
  login: Object.freeze({
    path: '/login',
  }),
  eventsList: Object.freeze({
    path: '/',
  }),
};
