import privateRoutes from '../../../entrypoint/ProtectedApp/privateRoutes';
import {
  KEYCLOAK_LOGOUT_URL,
  KEYCLOAK_REALM_URL,
} from '../../../utils/constants';

const KEYCLOAK_CONFIG = Object.freeze({
  authority: KEYCLOAK_REALM_URL,
  client_id: 'xquiz',
  clientId: 'xquiz',
  redirect_uri: window.location.origin.concat(privateRoutes.eventsList.path),
  response_type: 'code',
  scope: 'openid profile email role',
  response_mode: 'query',
});

export function getKeycloakConfig() {
  return KEYCLOAK_CONFIG;
}

export function confirmLogout() {
  const loginUrl = window.location.origin.concat('/');
  window.location.href = KEYCLOAK_LOGOUT_URL.concat('?redirect_uri=', loginUrl);
  localStorage.clear();
  sessionStorage.clear();
}
