import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd';
import { Status, useAsyncState } from 'react-async-states';
import { UserManager } from 'oidc-client';
import { getKeycloakConfig } from './keycloakConfig';
import { currentUserSource } from './data/ressource';
import { hasCodeInUrl } from './hasCodeInUrl';

export default function AuthProvider({ children }) {
  const manager = React.useMemo(() => new UserManager(getKeycloakConfig()), []);
  const navigate = useNavigate();
  const { state } = useAsyncState(
    {
      lazy: false,
      payload: { manager },
      source: currentUserSource,
      events: {
        change: (newState) => {
          if (
            newState.state.status === Status.success &&
            hasCodeInUrl(window.location)
          ) {
            navigate('/');
          }
        },
      },
    },
    [manager],
  );

  if (state.status === Status.success) {
    return children;
  }

  if (state.status === Status.error) {
    return (
      <Alert message="Error" type="error" description={state.data.toString()} />
    );
  }

  return <span>Connecting...</span>;
}
