import * as React from 'react';
import { IconProps } from '../../types';

export default function EditIcon({ className }: IconProps) {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.4026 2.01318L12.8246 0.54102C12.0518 -0.180281 10.801 -0.18039 10.028 0.540992L0.729781 9.16258L0.00963512 13.2367C-0.068734 13.6801 0.342682 14.0641 0.817848 13.991L5.18293 13.3189L14.421 4.64055C15.1924 3.92057 15.1997 2.75715 14.4026 2.01318ZM5.87235 8.51913C5.99821 8.63659 6.16318 8.6953 6.32812 8.6953C6.49306 8.6953 6.65803 8.63659 6.78389 8.51913L10.2711 5.2644L11.108 6.04558L6.15234 10.7011V9.5703H4.74609V8.2578H3.53449L8.52254 3.63247L9.35952 4.41366L5.87232 7.66835C5.62069 7.90329 5.62069 8.28419 5.87235 8.51913ZM2.40621 12.527L1.57819 11.7542L1.93385 9.74207L2.47292 9.24218H3.6914V10.5547H5.09765V11.6919L4.56205 12.1951L2.40621 12.527ZM13.5093 3.78981L13.5078 3.7912L13.5063 3.7926L12.0992 5.1145L9.52016 2.70744L10.9365 1.39412L10.938 1.39275L10.9394 1.39138C11.2074 1.1413 11.6419 1.13821 11.9131 1.39135L13.4909 2.86392C13.7803 3.134 13.7821 3.53518 13.5093 3.78981Z"
        fill="currentColor"
      />
    </svg>
  );
}
