import { useTranslation } from 'react-i18next';

const scopes = Object.freeze([
  'xQuiz',
  'participants',
  'editQuestion',
  'questions',
  'goodies',
  'confirmationModal',
  'logout',
  'successMsg',
  'seniorityLevelDetails',
  'eventsList',
]);

export const formattedMessages = Object.freeze({
  error: 'error',
  success: 'success',
  continue: 'continue',
  yes: 'yes',
  no: 'no',
  updating: 'updating',
  sending: 'sending',
  add: 'add',
  edit: 'edit',
  delete: 'delete',
  deleting: 'deleting',
  retry: 'retry',
  save: 'save',
  cancel: 'cancel',
  search: 'search',
  title: 'title',
  display: 'display',
  actions: 'actions',
  confirm: 'confirm',
  confirmation: 'Confirmation',
  Alert: 'Alert',
  from: 'From',
  to: 'To',

  answerMaxcharacters: 'The character limit is 100',
  maxLenghtTextArea: 'The character limit is 250',
  alertQuestion: 'Please make sure you select at least one correct answer',
  secondAlertQuestion: 'Please make sure you select at least one false answer',
  changeEvent: 'Change event',

  nb: 'NB : ',
  // footer
  footer: 'xQuiz Powered by',

  // Form rules
  required: 'required',

  // Patriipants
  xQuiz: `${scopes[0]}.title`,
  participants: `${scopes[1]}.title`,
  participantsFullName: `${scopes[1]}.fullName`,
  participantsTheme: `${scopes[1]}.theme`,
  participantsSubTheme: `${scopes[1]}.subTheme`,
  participantsQuizDate: `${scopes[1]}.quizDate`,
  participantsSeniorityLevel: `${scopes[1]}.seniorityLevel`,
  participantsTechnicalQuiz: `${scopes[1]}.technicalQuiz`,
  participantsCulturalQuiz: `${scopes[1]}.culturalQuiz`,
  participantsGoodie: `${scopes[1]}.goodie`,
  participantsCount: `${scopes[1]}.count`,
  participantsQuizReport: `${scopes[1]}.quizReport`,
  participantsGiveFeedback: `${scopes[1]}.giveFeedback`,
  participantsGiveGoodie: `${scopes[1]}.giveGoodie`,
  participantsViewDetails: `${scopes[1]}.viewDetails`,
  participantsEditFeedback: `${scopes[1]}.editFeedback`,
  participantsListOfGoodies: `${scopes[1]}.listOfGoodies`,
  participantsSelectedGoodie: `${scopes[1]}.selectedGoodie`,
  participantsShuffleGoodie: `${scopes[1]}.shuffleGoodie`,
  goodiesListEmpty: `${scopes[1]}.goodiesListEmpty`,
  giveGoodieSuccess: `${scopes[1]}.giveGoodieSuccess`,
  messageErrorShuffleGoodie: `${scopes[1]}.messageErrorShuffleGoodie`,
  participantsAddFeedback: `${scopes[1]}.addFeedback`,
  participantsAddFeedbackSave: `${scopes[1]}.save`,
  participantsAddFeedbackCancel: `${scopes[1]}.cancel`,
  participantsAddFeedbackTitle: `${scopes[1]}.feedbackLabel`,
  participantsAddFeedbackSuccess: `${scopes[1]}.feedbackSavedSuccess`,
  participantsAddFeedbackError: `${scopes[1]}.feedbackSavedError`,
  participantsRecapCulturalQuiz: `${scopes[1]}.recapCulturalQuiz`,
  participantsRecapTechnicalQuiz: `${scopes[1]}.recapTechnicalQuiz`,
  participantsRecapReviewerFeedback: `${scopes[1]}.recapReviewerFeedback`,
  participantsRecapCandidateFeedback: `${scopes[1]}.recapCandidateFeedback`,
  participantsReviewerName: `${scopes[1]}.participantsReviewerName`,
  noCulturalQuiz: `${scopes[1]}.noCulturalQuiz`,

  // list of questions
  questions: `${scopes[3]}.title`,
  questionsActionMenuDetails: `${scopes[3]}.actionMenuDetails`,
  questionDetails: `${scopes[3]}.questionDetails`,
  detailsAnswerNumber: `${scopes[3]}.detailsAnswerNumber`,
  detailsQuestionTitle: `${scopes[3]}.detailsQuestionTitle`,
  questionsActionMenuEdit: `${scopes[3]}.questionsActionMenuEdit`,
  questionsActionMenuShow: `${scopes[3]}.questionsActionMenuShow`,
  questionsActionMenuHide: `${scopes[3]}.questionsActionMenuHide`,
  questionsActionMenuDelete: `${scopes[3]}.questionsActionMenuDelete`,
  totalOfQuestions: `${scopes[3]}.totalOfQuestions`,
  addNewQuestion: `${scopes[3]}.addNewQuestion`,
  filterQuestionsByQuestion: `${scopes[3]}.filterQuestionsByQuestion`,
  questionsTheme: `${scopes[3]}.questionsTheme`,
  questionsSubTheme: `${scopes[3]}.questionsSubTheme`,
  questionsSeniorityLevel: `${scopes[3]}.questionsSeniorityLevel`,
  deleteQuestion: `${scopes[3]}.deleteQuestion`,
  deleteQuestionTitle: `${scopes[3]}.deleteQuestionTitle`,
  errorFetchingListData: `${scopes[3]}.errorFetchingListData`,
  addQuestionBtn: `${scopes[3]}.addQuestionBtn`,
  questionVisibility: `${scopes[3]}.questionVisibility`,
  visibleQuestion: `${scopes[3]}.visibleQuestion`,
  hiddenQuestion: `${scopes[3]}.hiddenQuestion`,
  hideQuestionLabel: `${scopes[3]}.hideQuestionLabel`,
  showQuestionLabel: `${scopes[3]}.showQuestionLabel`,
  visibilitySuccessMessage: `${scopes[3]}.visibilitySuccessMessage`,
  showQuestionModalLabel: `${scopes[3]}.showQuestionModalLabel`,
  hideQuestionModalLabel: `${scopes[3]}.hideQuestionModalLabel`,
  showQuestionModalTitle: `${scopes[3]}.showQuestionModalTitle`,
  hideQuestionModalTitle: `${scopes[3]}.hideQuestionModalTitle`,
  seniorityLevel: `${scopes[3]}.questionsSeniorityLevel`,

  // Edit question
  editQuestionTitle: `${scopes[2]}.editQuestionTitle`,
  question: `${scopes[2]}.question`,
  questionTheme: `${scopes[2]}.theme`,
  SubTheme: `${scopes[2]}.subTheme`,
  SeniorityLevel: `${scopes[2]}.seniorityLevel`,
  Answer: `${scopes[2]}.answer`,
  editQuestionBtn: `${scopes[2]}.editQuestionBtn`,
  questionPlaceholder: `${scopes[2]}.questionPlaceholder`,
  editQuestionSuccess: `${scopes[2]}.editQuestionSuccess`,
  listAnswers: `${scopes[2]}.listAnswers`,
  deleteAnswer: `${scopes[2]}.deleteAnswer`,
  deleteAnswerTitle: `${scopes[2]}.deleteAnswerTitle`,
  cancelEdit: `${scopes[2]}.cancelEdit`,
  answerField: `${scopes[2]}.answerField`,

  // Add question title
  addQuestionTitle: `${scopes[2]}.addQuestionTitle`,

  // Seniority Level Details
  Junior: `${scopes[8]}.Junior`,
  Intermediate: `${scopes[8]}.Intermediate`,
  Senior: `${scopes[8]}.Senior`,

  // goodies
  goodies: `${scopes[4]}.goodies`,
  goodiesActionMenuDelete: `${scopes[4]}.goodiesActionMenuDelete`,
  goodiesActionMenuEdit: `${scopes[4]}.goodiesActionMenuEdit`,
  goodiesName: `${scopes[4]}.goodiesName`,
  goodiesQuantity: `${scopes[4]}.goodiesQuantity`,
  goodiesCount: `${scopes[4]}.count`,
  goodiesLastUpdated: `${scopes[4]}.goodiesLastUpdated`,
  addgoodieWarning: `${scopes[4]}.addgoodieWarning`,
  scoreRange: `${scopes[4]}.scoreRange`,
  goodieName: `${scopes[4]}.goodieName`,
  goodieQuantity: `${scopes[4]}.goodieQuantity`,
  addGoodie: `${scopes[4]}.addGoodie`,
  addGoodieSuccess: `${scopes[4]}.addGoodieSuccess`,
  editGoodie: `${scopes[4]}.editGoodie`,
  deleteGoodie: `${scopes[4]}.deleteGoodie`,
  deleteGoodieTitle: `${scopes[4]}.deleteGoodieTitle`,

  // confirmationModal
  confirmCancel: `${scopes[5]}.confirmCancel`,
  confirmationTitle: `${scopes[5]}.title`,

  // logout
  logoutTitle: `${scopes[6]}.logoutTitle`,
  logoutConfirmation: `${scopes[6]}.logoutConfirmation`,

  // events list
  listsOfEvents: `${scopes[9]}.title`,
  eventsNotFound: `${scopes[9]}.notFound`,
  searchEvents: `${scopes[9]}.searchBy`,
  noEventData: `${scopes[9]}.noEventData`,
  addEvent: `${scopes[9]}.addEvent`,
  eventTitle: `${scopes[9]}.eventTitle`,
  eventAddress: `${scopes[9]}.eventLocation`,
  eventDate: `${scopes[9]}.eventDate`,
  uploadLogo: `${scopes[9]}.uploadLogo`,
  uploadBackground: `${scopes[9]}.uploadBackground`,
  primaryColor: `${scopes[9]}.primaryColor`,
  secondaryColor: `${scopes[9]}.secondaryColor`,
  registrationTypes: `${scopes[9]}.registrationTypes`,
  numberOfQuestions: `${scopes[9]}.numberOfQuestions`,
  quizDuration: `${scopes[9]}.quizDuration`,
  registrationTypePlaceholder: `${scopes[9]}.registrationTypePlaceholder`,
  seniorityTypePlaceholder: `${scopes[9]}.seniorityTypePlaceholder`,
  redirectTcChangeEvent: `${scopes[9]}.redirectTcChangeEvent`,
  missedBackgroundImage: `${scopes[9]}.missedBackgroundImage`,
  missedLogo: `${scopes[9]}.missedLogo`,
  eventColors: `${scopes[9]}.eventColors`,
  editEvent: `${scopes[9]}.editEvent`,
  confirmTextEditModal: `${scopes[9]}.confirmTextEditModal`,
  imageLenghtError: `${scopes[9]}.imageLenghtError`,
  logoUndefined: `${scopes[9]}.logoUndefined`,
  backgroundImageUndefined: `${scopes[9]}.backgroundImageUndefined`,
  resolutionError: `${scopes[9]}.resolutionError`,
  copyEventLink: `${scopes[9]}.copyEventLink`,

  // success messages
  deleteGoodiesuccessMsg: `${scopes[9]}.deletedGoodie`,
  deleteSuccessMsg: `${scopes[7]}.deletedQuestion`,
  addEventSuccessMessage: `${scopes[7]}.addEventSuccessMessage`,
  eventAddedSuccessfully: `${scopes[7]}.eventAddedSuccessfully`,
  eventActionMenuDelete: `${scopes[9]}.eventActionMenuDelete`,
  eventActionMenuEdit: `${scopes[9]}.eventActionMenuEdit`,
  deleteEventSuccessMs: `${scopes[7]}.deleteEvent`,
  nbMessage: `${scopes[9]}.nbMessage`,
  deleteEvent: `${scopes[9]}.deleteEvent`,
  deleteEventTitle: `${scopes[9]}.deleteEventTitle`,
});

export function getFormattedMessage(name: string) {
  const { t } = useTranslation();
  return t(formattedMessages[name]);
}
