import * as React from 'react';
import { Spin } from 'antd';
import AppProviders from './Providers';
import ProtectedApp from './ProtectedApp';
import './App.scss';

function Loader() {
  return (
    <div className="w-full h-full min-h-screen flex items-center justify-center">
      <Spin size="large" />
    </div>
  );
}

export default function App() {
  return (
    <React.Suspense fallback={<Loader />}>
      <AppProviders>
        <ProtectedApp />
      </AppProviders>
    </React.Suspense>
  );
}
