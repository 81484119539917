import * as React from 'react';
import { IconProps } from '../../types';

export default function MenuIcon({ className }: IconProps) {
  return (
    <svg
      width="50"
      height="21"
      viewBox="0 0 5 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.5 8.60396C2.1717 8.60396 1.84661 8.66584 1.54329 8.78605C1.23998 8.90627 0.96438 9.08247 0.732234 9.3046C0.500087 9.52673 0.315938 9.79044 0.190301 10.0807C0.0646645 10.3709 2.58521e-07 10.682 2.58521e-07 10.9961C2.58521e-07 11.3102 0.0646645 11.6213 0.190301 11.9115C0.315938 12.2017 0.500087 12.4655 0.732234 12.6876C0.96438 12.9097 1.23998 13.0859 1.54329 13.2061C1.84661 13.3264 2.1717 13.3882 2.5 13.3882C3.16304 13.3881 3.79887 13.1359 4.2676 12.6872C4.73634 12.2385 4.99958 11.63 4.99943 10.9956C4.99928 10.3611 4.73574 9.75273 4.2668 9.30422C3.79785 8.85571 3.16191 8.60382 2.49886 8.60396H2.5ZM2.5 5.77818C2.82816 5.77804 3.15307 5.71605 3.45619 5.59576C3.75931 5.47547 4.0347 5.29922 4.26663 5.07709C4.49857 4.85496 4.68251 4.5913 4.80795 4.30115C4.93339 4.011 4.99788 3.70005 4.99773 3.38605C4.99758 3.07206 4.9328 2.76116 4.80708 2.47112C4.68136 2.18108 4.49717 1.91757 4.26503 1.69565C4.03288 1.47372 3.75732 1.29771 3.45409 1.17769C3.15086 1.05766 2.82588 0.995951 2.49773 0.996094C1.83499 0.996382 1.19951 1.24857 0.731097 1.69718C0.262682 2.14579 -0.000300987 2.75408 2.58521e-07 3.38823C0.000301504 4.02237 0.263863 4.63043 0.732704 5.07863C1.20154 5.52683 1.83726 5.77847 2.5 5.77818ZM2.5 16.2118C1.83696 16.2118 1.20107 16.4639 0.732234 16.9125C0.263393 17.3611 2.58521e-07 17.9695 2.58521e-07 18.604C2.58521e-07 19.2384 0.263393 19.8468 0.732234 20.2955C1.20107 20.7441 1.83696 20.9961 2.5 20.9961C3.16304 20.9961 3.79893 20.7441 4.26777 20.2955C4.73661 19.8468 5 19.2384 5 18.604C5 17.9695 4.73661 17.3611 4.26777 16.9125C3.79893 16.4639 3.16304 16.2118 2.5 16.2118Z"
        fill="currentCOlor"
      />
    </svg>
  );
}
