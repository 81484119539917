import React from 'react';

function LogoutIcon({ color = 'black' }: { color: string }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4906 2.61001L10.3407 9.07213L14.2122 9.06488L14.234 20.6793L16.8149 20.6745L16.7932 9.06005L20.6647 9.0528L15.4906 2.61001Z"
        fill={color}
      />
      <path
        d="M27.133 16.785C27.1344 15.259 26.8329 13.7479 26.2462 12.3392C25.6594 10.9306 24.7989 9.65237 23.7146 8.57864L21.8933 10.4068C23.6025 12.1096 24.5475 14.3766 24.552 16.7898C24.5565 19.203 23.62 21.4735 21.9172 23.1827C20.2144 24.8919 17.9474 25.8369 15.5342 25.8414C13.121 25.846 10.8505 24.9094 9.14132 23.2066C7.4321 21.5038 6.48708 19.2368 6.48257 16.8236C6.47805 14.4104 7.41456 12.14 9.1174 10.4307L7.28923 8.60939C5.0995 10.806 3.89577 13.7248 3.90158 16.8285C3.9074 19.9321 5.12204 22.8463 7.31998 25.0348C9.51664 27.2245 12.4354 28.4282 15.539 28.4224C18.6427 28.4166 21.5569 27.202 23.7454 25.004C24.8256 23.9262 25.6813 22.6448 26.2628 21.234C26.8443 19.8231 27.1401 18.3109 27.133 16.785Z"
        fill={color}
      />
    </svg>
  );
}

export default LogoutIcon;
