import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { formattedMessages } from '../../../../utils/formattedMessages';
import privateRoutes from '../../privateRoutes';
import Logo from '../../../../assets/logo.svg';
import { ParticipantsIcon } from '../../../../assets/Icons';
import QuestionsIcon from '../../../../assets/Icons/QuestionsIcon';
import IconWrapper from '../../../../components/IconWrapper';
import './styles.scss';
import GoodiesIcon from '../../../../assets/Icons/GoodiesIcon';
import Settings from '../../../../assets/Icons/setting';

const MenuItems = [
  {
    icon: ParticipantsIcon,
    title: 'participants',
    path: privateRoutes.participants.path,
  },
  {
    icon: QuestionsIcon,
    title: 'questions',
    path: privateRoutes.questions.path,
  },
  {
    icon: GoodiesIcon,
    title: 'goodies',
    path: privateRoutes.goodies.path,
  },
];

function SideMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="h-full w-full pt-10">
      <div className="w-40 mx-auto">
        <img src={Logo} alt="xquiz-logo w-full" />
      </div>
      <div className="mt-5 pr-5">
        {MenuItems.map((item) => (
          <NavLink
            key={item.title}
            to={item.path}
            className={({ isActive }) =>
              classNames(
                `flex items-center px-2 py-3 rounded-r-full text-white capitalize mb-2 ${item?.title}`,
                {
                  'side-menu__active font-bold': isActive,
                },
              )
            }
          >
            <IconWrapper icon={item.icon} />
            <p className="px-1 py-0 m-0">{t(formattedMessages[item.title])}</p>
          </NavLink>
        ))}
      </div>
      <button
        type="button"
        onClick={() => navigate(privateRoutes.eventsList.path)}
        className="flex justify-center items-center gap-1 absolute bottom-10
        pl-2 text-white font-normal hover:!text-[#8e54e9] transition-all"
      >
        <Settings />
        {t(formattedMessages.redirectTcChangeEvent)}
      </button>
    </div>
  );
}

export default SideMenu;
