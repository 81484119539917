import { t } from 'i18next';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { formattedMessages } from '../../../../utils/formattedMessages';
import privateRoutes from '../../privateRoutes';
import LogoutButton from '../../../../components/LogoutButton';

function Header() {
  const location = useLocation();

  function HeaderTitle() {
    switch (location?.pathname) {
      case privateRoutes.questions.path:
        return t(formattedMessages.questions);
      case privateRoutes.participants.path:
        return t(formattedMessages.participants);
      case privateRoutes.goodies.path:
        return t(formattedMessages.goodies);
      default:
        break;
    }
  }

  return (
    <div className="bg-white w-full h-full flex items-center">
      <div className="w-full px-2 md:px-6 flex items-center justify-between mx-auto">
        <div className="flex items-center justify-between text-white w-full">
          <h1 className="text-2xl p-0 m-0">{HeaderTitle()}</h1>
          <LogoutButton />
        </div>
      </div>
    </div>
  );
}
export default Header;
